import Grid from '../../../components/Grid';
import Section from '../../../components/Section';
import { CardsLoader } from '../../../components/Skeleton/CardsLoader';
import { TableSkeleton } from '../../../components/Skeleton/TableLoader';
import Icons from '../../../components/icons/Icons';
import NftPreview from '../../../components/nft/NftPreview';
import { resources } from '../../../constants';
import CosmosProvider from '../../../helpers/cosmosHelper';
import utils from '../../../utils';
import NotFound404 from '../../NotFound404';
import './Event.scss';
import EventProvider, { useEvent } from './EventProvider';
import Level from './Level';
import PfpBuilder from './PfpBuilder';
import { RaffleBanners } from './RaffleBanners';
import MintNft from './mint/MintNft';

const NftDetails = () => {
  const [{ communityName, event, token, nftProfile, setIsEdit }] = useEvent();

  const openseaLink = resources.links.opensea(token.chainId, token.address, token.tokenId);
  const etherscanLink = `${utils.getChainDetails(token?.chainId)?.blockExplorers?.default?.url}/address/${token?.address}`;
  const tweetText = utils.tweetTemplateParser(event?.config?.tweetConfig?.text || `Just minted my NFT from @0xmercle, Mint yours here: <<community_events_page>>`, {
    communityEventsPage: `${window.location.host}/${communityName}/event`,
    openseaLink,
  });
  const tweetLink = resources.links.twitter.tweet(tweetText);

  const canEquip = event?.inventoryConfig?.canEquip !== false;

  return (
    <div className='NftDetails-container info ellipse' columns={2}>
      <h1 className='ellipse'>
        {nftProfile?.name} #{token?.tokenId}
      </h1>
      <p>{nftProfile?.description}</p>
      <Grid className='nftActionPanel' columns={2}>
        <button disabled={true}>{Object.keys(nftProfile?.properties?.XP?.claims || {})?.length} Attestations</button>
        <button disabled={true}>{nftProfile?.properties?.['De-Fi']?.value || nftProfile?.properties?.['DeFi Savvy']?.value || 0} De-Fi XP</button>
        <button disabled={true}>{nftProfile?.properties?.Social?.value || nftProfile?.properties?.['Social Energy']?.value || 0} Social XP</button>
        <button disabled={true}>
          {nftProfile?.properties?.Community?.value || nftProfile?.properties?.['Token Guru']?.value || 0}
          {nftProfile?.properties?.['Token Guru']?.value ? ' Token Guru' : ' Community XP'}
        </button>
        <a className='clickable' href={openseaLink} target='_blank'>
          <Icons icon={'store'} /> Marketplace
        </a>
        <a className='clickable' href={etherscanLink} target='_blank'>
          <Icons icon={'graph'} /> Block explorer
        </a>
        <a className='clickable' href={tweetLink} target='_blank'>
          <Icons icon={'share'} /> Share on X
        </a>
        {canEquip ? (
          <button className='primary' onClick={() => setIsEdit(true)}>
            Edit NFT
          </button>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

const Pfp = () => {
  const [{ communityName, nftProfile, campaign, isEdit, event }] = useEvent();

  const renderActionPanel = () => {
    if (!nftProfile) return <MintNft />;
    if (isEdit) return <PfpBuilder />;

    return <NftDetails />;
  };

  return (
    <>
      <Grid className='Pfp-container' title={event?.name || communityName} columns={2}>
        {!isEdit ?<div>
          <NftPreview className='eventNftPreview' src={nftProfile?.animation_url} template={campaign?.template} />
          {nftProfile?.properties?.Level?.details?.progressBar?.image && <img className='progressBar' src={nftProfile?.properties?.Level?.details?.progressBar?.image} />}
        </div> : <></>}
        {renderActionPanel()}
      </Grid>
    </>
  );
};

const Event = () => {
  const [{ event }] = useEvent();
  if (event === undefined)
    return (
      <>
        <CardsLoader rows={1} columns={2} columnHeight={600} />
        <TableSkeleton rowsWithHeight={[50, 30, 30]} gap={10} />
        <div style={{ margin: '40px 0' }} />
        <CardsLoader rows={5} columns={1} columnHeight={60} />
      </>
    );

  if (!event) return <NotFound404 />;
  return (
    <div className='Event-container'>
      <Pfp />
      <RaffleBanners />
      <Section className='Description-container' title='What is it?'>
        {event?.descIsHtml ? <div dangerouslySetInnerHTML={{ __html: event?.description }}></div> : <p>{event?.description}</p>}
      </Section>
      {Object.keys(event.eventNames).map((levelId, i) => (
        <Level key={i} title={event.eventNames[levelId].name} levelId={levelId} />
      ))}
    </div>
  );
};

const P = () => {
  return (
    <EventProvider>
      <CosmosProvider>
        <Event />
      </CosmosProvider>
    </EventProvider>
  );
};

export default P;
